import './App.css';
import { Guitars } from './Guitars';
import React from 'react';


function App() {
  return (
    <div className="App">
      <Guitars />
    </div>
  );
}

export default App;
